/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #1a1a1a;
  color: #fff;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* Navigation */
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #039be5;
}

/* Fullscreen sections */
section {
  min-height: 100vh; /* Each section takes up full screen height */
  display: flex;
  flex-direction: column; /* Allows flex layout to position header at top */
  justify-content: flex-start; /* Header is placed at the top */
  align-items: center;
  padding: 40px 20px; /* Adds space around the section */
  text-align: center;
}

/* Typography scaling for responsiveness */
h1 {
  font-size: 5vw; /* Scales with viewport width */
}

h2 {
  font-size: 4vw;
}

p {
  font-size: 2.5vw;
}

/* Adjust the font size for smaller screens using media queries */
@media (max-width: 768px) {
  h1 {
    font-size: 8vw;
  }

  h2 {
    font-size: 6vw;
  }

  p {
    font-size: 4vw;
  }
} /* <- Closing bracket for the smaller screen media query */

/* Add specific styling for larger screens */
@media (min-width: 1200px) {
  h1 {
    font-size: 3.5vw;
  }

  h2 {
    font-size: 2.5vw;
  }

  p {
    font-size: 1.5vw;
  }
}

/* Hero Section */
.hero-section {
  background-color: #1a1a1a;
}

.hero-section .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1; /* Ensures the content fills the space below the title */
}

.subtitle {
  font-size: 1.5rem;
  color: #039be5;
}

/* Apps Section */
.apps-section {
  background-color: #333;
}

.apps-section .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* About Section */
.about-section {
  background-color: #1a1a1a;
}

.about-section .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

/* Contact Section */
.contact-section {
  background-color: #333;
}

.contact-section .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.contact-section a {
  color: #039be5;
  text-decoration: none;
}

.contact-section a:hover {
  text-decoration: underline;
}

/* Footer */
.footer {
  background-color: #1a1a1a;
  padding: 20px;
}